import { createApp } from 'vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import router from './router'
import App from './App.vue'
import i18n from './i18n'
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - Help Center - XSONIC" || "XSONIC"
  }
  next()
})

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(FloatingVue);
app.mount('#app');