<template>
  <!-- Navbar -->
  <header class="navbar navbar-expand-md sticky-top d-print-none">
    <div class="container-xl">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
        aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <div>
          <a href="/">
            <img src="/img/logo.png" width="110" height="32" alt="Tabler" class="navbar-brand-image"> 
          </a>
          <div class="d-sm-none">
            <div class="nav-item text-reset text-center chaneLanguage">
              <a href="#"  @click="changeLanguage('en')">EN</a> | <a href="#" @click="changeLanguage('zh')">中</a>
            </div>
          </div>
        </div>
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="d-none d-md-flex">
          <div class="nav-item dropdown">
            <a href="#" class="nav-link d-flex lh-1 text-reset p-0 show" data-bs-toggle="dropdown"
              aria-label="Open user menu" aria-expanded="true">
              {{ $t("Language") }}
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow " data-bs-popper="static">
              <a href="#" class="dropdown-item" @click="changeLanguage('zh')">简体中文</a>
              <a href="#" class="dropdown-item" @click="changeLanguage('en')">English</a>
            </div>
          </div>
          <div @click="themeModeChange" class="nav-link px-10 hide-theme-dark themeModeChange" data-bs-toggle="tooltip"
            data-bs-placement="bottom" aria-label="Enable dark mode" data-bs-original-title="Enable dark mode">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
              stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
            </svg>
          </div>
          <div @click="themeModeChange" class="nav-link px-10 hide-theme-light themeModeChange" data-bs-toggle="tooltip"
            data-bs-placement="bottom" aria-label="Enable light mode" data-bs-original-title="Enable light mode">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
              stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
              <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" href="/home">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                </span>
                <span class="nav-link-title">
                  {{ $t('Home') }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { global } from '../global.js'
export default {
  name: 'HomerBlock',
  data() {
    return {
      global: global,
    };
  }, methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
    },
    themeModeChange() {
      var themeStorageKey = "tablerTheme";
      var currentTheme = localStorage.getItem(themeStorageKey);
      if (!currentTheme) {
        currentTheme = 'dark';
      } else {
        if (currentTheme == "dark") {
          currentTheme = 'light';
        } else {
          currentTheme = 'dark';
        }
      }
      localStorage.setItem(themeStorageKey, currentTheme);
      if (currentTheme === 'dark') {
        document.body.setAttribute("data-bs-theme", currentTheme);
      } else {
        document.body.removeAttribute("data-bs-theme");
      }
    }
  }
}
</script>
<style scoped>
.themeModeChange {
  cursor: pointer;
}
.chaneLanguage{
  margin-top: 15px;font-size: 14px;
}
</style>