
<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row  mt-5">
          <div class="col-12">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-7">
                <div class="input-group">
                  <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">{{ productName() }}
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="/search" v-for="item in list_products" :key="item.id"
                      @click="changeProduct(item)">
                      {{ item.name }}</a>
                  </div>
                  <input type="text" class="form-control" placeholder="Search FAQs…" v-model="keywords" autocomplete="off"
                    @keyup.enter="search()" />
                </div>
              </div>
              <div class="col-1">
                <a href="/search" class="btn btn-icon" aria-label="Button" @click="search()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                  </svg>
                </a>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-5">
          <div class="col-12 text-center text-secondary">
            <div class="btn-list">
              <button class="btn">AIRSTEP <span class="badge bg-blue text-blue-fg ms-2">1</span></button>
              <button class="btn">AIRSTEP PLAY <span class="badge bg-blue text-azure-fg ms-2">2</span></button>
              <button class="btn">AIRSTEP SPK <span class="badge bg-blue text-indigo-fg ms-2">3</span></button>
              <button class="btn">AIRSTEP YT <span class="badge bg-blue text-purple-fg ms-2">4</span></button>
              <button class="btn">AIRSTEP BW <span class="badge bg-blue text-pink-fg ms-2">5</span></button>
            </div>
          </div>
        </div> -->
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12">
            <div class="card">
              <div class="card-header ">
                <h3 class="card-title">About {{ pageConfigTotal.total }} result
                </h3>
              </div>
              <NoData :show="dataList.length == 0" />
              <div class="list-group list-group-flush list-group-hoverable">
                <div class="row g-0" v-for="item in  dataList" :key="item.id">
                  <div class="col-auto" v-if="getTitle(item) != ''">
                    <div class="card-body"></div>
                  </div>
                  <div class="col">
                    <div class="card-body ps-0" v-if="getTitle(item) != ''">
                      <div class="row">
                        <div class="col">
                          <h3 class="mb-0"><a :href="'/faq/' + item.pid + '/' + item.id">{{ getTitle(item) }}</a></h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md">
                          <div class="mt-3 list-inline list-inline-dots mb-0 text-secondary d-sm-block ">
                            {{ getContent(item) }}
                          </div>
                        </div>
                        <div class="col-md-auto">
                          <div class="mt-3 badges">
                            <span class="badge badge-outline text-secondary fw-normal badge-pill"> {{
                              getCategoryTitle(item.category) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <Page :page-config="pageConfigTotal" @changeCurrentPage="changePage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../../components/HeaderBlock.vue'
import FooterBlock from '../../components/FooterBlock.vue'
import Page from '../../components/Pagination.vue'
import NoData from '../../components/NoData.vue'
import showdown from 'showdown'
//
import { global } from '../../global.js'
export default {
  name: 'SearchPage',
  data() {
    return {
      global: global,
      dataList: [],
      pageConfigTotal: { total: 0, pageSize: 0, pageNo: 1, pageTotal: 0 },
      keywords: "",
      current_product_id: 0,
      current_product_name: "",
    };
  },
  components: {
    HeaderBlock,
    Page,
    FooterBlock,
    NoData
  },
  methods: {
    getTitle: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.title_zh;
      } else {
        return item.title;
      }
    },
    getContent: function (item) {
      if (this.$i18n.locale == "zh") {
        return this.filterMarkdownTags(item.content_zh);
      } else {
        return this.filterMarkdownTags(item.content);
      }
    },
    filterMarkdownTags: function (html) {
      var converter = new showdown.Converter();
      html = converter.makeHtml(html);
      return html.replace(/(<([^>]+)>)/ig, '').substring(0, 300);
    },
    getCategoryTitle: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.name_zh;
      } else {
        return item.name;
      }
    },
    productName: function () {
      if (this.current_product_name == "" || this.current_product_name == undefined) {
        return this.$t("Products");
      } else {
        return this.current_product_name;
      }
    },
    search: function (page) {
      if (this.keywords != "") {
        global.postData("help_center/product_faq_search", {
          page: page,
          product_id: parseInt(this.current_product_id),
          keywords: this.keywords
        })
          .then(data => {
            if (data != null && data.code == 200) {
              this.dataList = data.body.list;
              for (let index = 0; index < this.dataList.length; index++) {
                const ele = this.dataList[index];
                let p = JSON.parse(ele.product_id); console.log(p);
                this.dataList[index].pid = p[0]
              }

              this.pageConfigTotal = { total: data.body.total, pageSize: data.body.pageSize, pageNo: data.body.pageNo, pageTotal: data.body.pageTotal };
            }
          });
      }

    },
    changePage(pageNo) {
      this.search(pageNo);
    },
    changeProduct: function (item) {
      this.current_product_id = item.id;
      this.current_product_name = item.name;
    },
    get_products_list: function () {
      global.postData("help_center/list_products")
        .then(data => {
          if (data.code == 200) {
            this.list_products = data.body;
          }
        });
    },
  },
  mounted() {
    this.get_products_list();
    const query = this.$route.query;
    if (query.product_id != undefined) { this.current_product_id = parseInt(query.product_id); }
    if (query.product_name != undefined) { this.current_product_name = query.product_name; }
    if (query.keywords != undefined) { this.keywords = query.keywords; }
    this.search(0);
  },
}
</script>
<style scoped></style>