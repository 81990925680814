export default {
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "AppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
  "UserManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明书"])},
  "QuickStartGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速入门"])},
  "ReadInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍 / 常见问题"])},
  "DirectLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制分享"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
  "EmailUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "NoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有找到任何结果"])},
  "NoResultsAdj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试调整您搜索的关键词以找到您要查找的内容。"])},
  "CopyShareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享链接"])},
  "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制成功"])},
  "FirmwareUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固件升级"])},
  "ULooperUpdater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ULooper更新"])},
  "ReleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日志"])},
  "BetaTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意：这是测试版，在使用过程中可能会遇到不稳定的情况。"])}
}