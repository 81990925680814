import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}
const getDefaultLang = () => {
  //用户指定了默认语言时，使用用户指定的
  if (localStorage.getItem('lang') != null) {
    return localStorage.getItem('lang');
  } else {
    if (navigator.language.indexOf("zh") > -1) {
      return 'zh';
    } else {
      return 'en';
    }
  }
}
export default createI18n({
  locale: getDefaultLang(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
