<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper" @click="hideOutline()">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ item.title }} </h1>
          </div>
        </div>
        <div class="card-body">
          <ol class="breadcrumb breadcrumb-arrows">
            <li class="breadcrumb-item"><a href="/home"> {{ $t('Home') }}</a></li>
            <li class="breadcrumb-item"><a :href="'/product/' + product.short_name.toLowerCase()">{{ product.name }}</a>
            </li>
            <li class="breadcrumb-item">{{ item.title }}</li>
          </ol>
        </div>
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row">
          <div class="card col-md-3 poutline">
          </div>
          <div class="col-md-9">
            <div class="card card-lg" id="previewWrap">
              <div class="" id="preview"></div>
            </div>
          </div>
          <div class="col-md-3 markdown-menu" id="markdownMenu" v-show="outlineDisplay">
            <div class="row g-2 mb-3 search-bar">
              <div class="col">
                <input type="text" class="form-control" placeholder="Search …" v-model="searchKey"
                  @keyup.enter="searchBtn()">
              </div>
              <div class="col-auto">
                <div class="btn btn-icon" @click="searchBtn()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div id="outline"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GoTop />
  <button class="navbar-toggler left-outline-menu" type="button" data-bs-toggle="collapse"
    :aria-expanded="outlineDisplay" aria-label="Toggle navigation" data-bs-target="#outline" @click="toggleOutline()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <!-- .. -->
  <div class="modal modal-blur fade " v-bind:class="{ show: modalShown }" id="modal-full-width" tabindex="-1"
    role="dialog" aria-hidden="true" @click="hideModal()">
    <div class="modal-dialog modal-full-width modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body" style="text-align: center ;">
          <img :src="modalImg" style="width: 100%;">
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../../components/HeaderBlock.vue'
import FooterBlock from '../../components/FooterBlock.vue'
import VditorPreview from 'vditor/dist/method.min'
import GoTop from "../../components/GoTop.vue";
import Mark from 'mark.js/dist/mark.min.js'
// VditorPreview.mermaidRender(document)
import '../../../public/css/markdown-preview.css'

import { global } from '../../global.js'
export default {
  name: 'ProductItemPage',
  data() {
    return {
      searchKey: "",
      global: global,
      outlineDisplay: false,
      item: {},
      langPageUrl: {
        zh: '',
        en: '',
      },
      product: { name: 'AIRSTEP', short_name: 'as' },
      modalShown: false,
      modalImg: "",
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
    GoTop,
  },
  mounted() {
    this.getItem();
  },
  methods: {
    hideModal: function () {
      this.modalShown = false;
    },
    hideOutline: function () {
      if (window.outerWidth < 768) {
        this.outlineDisplay = false;
      }
    },
    toggleOutline: function () {
      this.outlineDisplay = !this.outlineDisplay;
    },
    searchBtn: function () {
      var context = document.querySelector(".page-body");
      var instance = new Mark(context);
      instance.unmark();
      if (this.searchKey != "") {
        instance.mark(this.searchKey);
        if (document.querySelector('mark') != null) {
          document.querySelectorAll('mark')[0].scrollIntoView({ block: 'center', behavior: 'smooth' }, 1000);
        }
      }
    },
    getContent: function () {
      let that = this;
      const resizeEl = () => {
        const vditorOutlineEL = document.querySelector(".vditor-outline");
        if (vditorOutlineEL) {
          vditorOutlineEL.style.height = (window.innerHeight - 160) + "px";
          let poutline_width = document.querySelector(".poutline").offsetWidth;
          vditorOutlineEL.style.width = poutline_width + "px";
          document.querySelector(".markdown-menu").style.width = poutline_width + "px";
          const scrollTop = window.scrollY;
          let vditor_outline_height = vditorOutlineEL.offsetHeight;
          if (scrollTop <= 145 && vditor_outline_height) {
            vditorOutlineEL.style.height = vditor_outline_height + scrollTop + "px";
          }
          //
          if (window.outerWidth < 768) {
            if (window.outerWidth > 320) {
              vditorOutlineEL.style.width = "320px";
            } else {
              vditorOutlineEL.style.width = (poutline_width * 0.8) + "px";
            }
            vditorOutlineEL.style.height = window.innerHeight - 50 + "px";
            document.querySelector("header .navbar-toggler").style.visibility = "hidden";
          } else {
            document.querySelector("header .navbar-toggler").style.visibility = "show";
            const content_height = document.querySelector("#preview").offsetHeight;
            const scroll_top = document.documentElement.scrollTop || document.body.scrollTop;
            if (window.innerHeight - (content_height - scroll_top) > 220) {
              vditorOutlineEL.style.height = vditor_outline_height - 220 + "px";
              vditorOutlineEL.scrollTo({
                top: content_height,
                // behavior: 'smooth'
              });
            }
          }
        }
      }
      const initOutline = () => {
        const markdownMenu = document.querySelector(".markdown-menu");
        const headingElements = []
        let uniqueArr = Array.from(new Set(headerCodes));
        if (uniqueArr.length > 0) {
          uniqueArr.sort((a, b) => a - b);
          Array.from(document.getElementById('preview').children).forEach((item) => {
            if (item.tagName.length === 2 && item.tagName !== 'HR' && item.tagName.indexOf('H') === 0) {
              if (uniqueArr.length == 1) {
                if (item.tagName == "H" + uniqueArr[0]) {
                  headingElements.push(item)
                }
              } else if (uniqueArr.length > 1) {
                if (item.tagName == "H" + uniqueArr[0] || item.tagName == "H" + uniqueArr[1]) {
                  headingElements.push(item)
                }
              }
            }
          })
        }
        if (markdownMenu) {
          if (window.scrollY >= 145) {
            markdownMenu.style.top = "90px";
          } else {
            markdownMenu.style.top = (240 - window.scrollY) + 'px';
          }
        }

        if (window.outerWidth < 768) {
          this.outlineDisplay = false;
        } else {
          this.outlineDisplay = true;
        }
        resizeEl();
        window.addEventListener('resize', () => {
          resizeEl();
          if (window.outerWidth < 768) {
            this.outlineDisplay = false;
          } else {
            this.outlineDisplay = true;
          }
        });
        let toc = []
        window.addEventListener('scroll', () => {
          const scrollTop = window.scrollY
          toc = []
          headingElements.forEach((item) => {
            toc.push({
              id: item.id,
              offsetTop: item.offsetTop,
            })
          })
          resizeEl();
          if (markdownMenu) {
            if (scrollTop >= 145) {
              markdownMenu.style.top = "90px";
            } else {
              markdownMenu.style.top = (240 - scrollTop) + 'px';
            }
          }

          const currentElement = document.querySelector('.vditor-outline__item--current')
          for (let i = 0, iMax = toc.length; i < iMax; i++) {
            if (scrollTop < toc[i].offsetTop - 30) {
              if (currentElement) {
                currentElement.classList.remove('vditor-outline__item--current')
              }
              let index = i > 0 ? i - 1 : 0
              document.querySelector('span[data-target-id="' + toc[index].id + '"]').classList.add('vditor-outline__item--current')
              break
            }
          }
        })
      }
      // const GetAllAnchor = () => {
      //   let el = document.querySelectorAll("#preview a");
      //   el.forEach(function (element) {
      //     var href = element.getAttribute("href");
      //     element.addEventListener("click", function (event) {
      //       event.preventDefault();
      //       if (href.startsWith("#")) {
      //         let anchor = href.substring(1);
      //         let anchorEl = document.getElementById(anchor);
      //         if (anchorEl) {
      //           window.scrollTo(0, anchorEl.offsetTop);
      //         }
      //       } else {
      //         location.href = href
      //       }
      //     });
      //   });
      // }
      const previewElement = document.getElementById('preview');
      const outlineElement = document.getElementById('outline');

      previewElement.addEventListener('click', function (event) {
        if (event.target.tagName === 'IMG') {
          that.modalShown = true;
          that.modalImg = event.target.src;
          //VditorPreview.previewImage(event.target)
        }
      })
      // let hCount = 0;
      // let levelCode = 0;
      // let prevNodeLevel = 0;
      let headerCodes = [];
      VditorPreview.preview(previewElement,
        this.item.content, {
        speech: {
          enable: false,
        },
        markdown: {
          toc: false,
          mark: true,
        },
        anchor: 2,
        after() {
          VditorPreview.outlineRender(previewElement, outlineElement);
          if (outlineElement.innerText.trim() !== '') {
            outlineElement.style.display = 'block';
            initOutline()
          }
          // GetAllAnchor();
        },
        renderers: {
          // renderLinkDest: (node, entering) => {
          //   const src = node.TokensStr();
          //   console.log('renderImage', src, node.__internal_object__.Parent.Type);
          //   if (node.__internal_object__.Parent.Type === 34) {

          //     if (entering) {
          //       return [`<a data-fslightbox="gallery" href="${src}"><div class="img-responsive img-responsive-3x1 rounded-3 border" style="background-image: url(${src})"></div>`, window.Lute.WalkContinue]
          //     }
          //     return ["</a>", window.Lute.WalkContinue]
          //   } else {
          //     if (entering) {
          //       return [`<a href="${src}">`, window.Lute.WalkContinue]
          //     }
          //     return ["</a>", window.Lute.WalkContinue]
          //   }

          // },
          // renderImage: (node, entering) => {
          //   const src = node.TokensStr();
          //   console.log('renderImage', src);
          //   if (entering) {
          //     return ["", window.Lute.WalkContinue]
          //   }
          //   return ["", window.Lute.WalkContinue]
          // },
          // renderHeading: (node, entering) => {
          //   // hCount++;
          //   let nodeLevelCode = node.__internal_object__.HeadingLevel;
          //   headerCodes.push(nodeLevelCode);
          //   // if (levelCode >= 0 && levelCode <= 6) {
          //   //   if (nodeLevelCode > prevNodeLevel) {
          //   //     levelCode += 1;
          //   //   } else {
          //   //     levelCode = levelCode - (prevNodeLevel - nodeLevelCode);
          //   //   }
          //   // } else {
          //   //   levelCode = 0;
          //   // }
          //   // prevNodeLevel = nodeLevelCode;

          //   const id = window.Lute.GetHeadingID(node)

          //   console.log(id);
          //   if (entering) {
          //     return [`<h${nodeLevelCode} id="${id}" class="vditor__heading"><span class="prefix"></span><span>`, window.Lute.WalkContinue]
          //   } else {
          //     return [`</span><a id="vditorAnchor-${id}" class="vditor-anchor" href="#${id}"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 15l6 -6"></path><path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path><path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"></path></svg></a></h${nodeLevelCode}>`, window.Lute.WalkContinue]
          //   }
          // },
        },
      })
    },
    getItem: function (id = 0) {
      global.postData("help_center/user_manual_item", {
        id: id == 0 ? this.$route.params.id : id,
      })
        .then(data => {
          if (data != null) {
            if (data.code == 200) {
              this.item = data.body;
              this.product = data.body.product;
              this.getContent();
              if (id == 0) {
                this.getProductItem();
              }
            }
          }
        });
    },
    getProductItem: function () {
      global.postData("help_center/product_item", {
        short_name: this.item.product.short_name,
      })
        .then(data => {
          if (data != null) {
            if (data.code == 200) {
              this.langPageUrl['zh'] = data.body.user_manual_url_zh;
              this.langPageUrl['en'] = data.body.user_manual_url;
            }
          }
        });
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(_to, _from) {
      let fullPath = this.$route.fullPath;
      let langPageUrl = this.langPageUrl[this.$i18n.locale];
      if (fullPath != langPageUrl) {
        this.$router.push({
          path: langPageUrl
        })
        this.getItem(langPageUrl.replace(/\/user_manual\//g, ""));
      }
    }
  },
}
</script>
<style scoped>
#preview {
  padding: 20px 30px;
}

.vditor-outline {
  overflow-y: scroll;
  width: 320px;
}

.markdown-menu {
  position: fixed;
  top: 240px;
  width: 325px;
}

.left-outline-menu {
  display: none;
}

@media screen and (max-width: 768px) {
  #previewWrap {
    padding-top: 0px;
  }

  .left-outline-menu {
    display: block;
  }

  .vditor-outline {
    height: 100%;
  }

  #outline {
    /* display: none !important; */
    position: fixed;
    top: 50px;
    left: 0;
    padding-top: 20px;
    background-color: white;
    border-right: 2px rgb(238, 238, 238) solid;
  }

  .poutline {
    border-width: 0;
  }

  .markdown-menu {
    position: unset;
  }

  .left-outline-menu {
    position: fixed;
    border: 0;
    width: 2rem;
    height: 2rem;
    left: 8px;
    top: 12px;
    font-size: 16px;
    z-index: 999999;
  }
}

.markdown {
  min-height: 400px;
}

.markdown img {
  width: 100%;
}

.markdown table {
  display: initial;
}

.search-bar {
  margin-left: 15px;
  margin-right: 15px;
}

mark {
  background: orange;
  color: black;
}

.show {
  display: block !important;
}
</style>