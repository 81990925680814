<template>
  <div v-if="isShow">
    <div class="container-xl " v-if="isShow" style="text-align:left">
      <ul class="list-group list-group-flush placeholder-glow">
        <li class="list-group-item">
          <div class="row align-items-center">
            <div class="col-10">
              <div class="placeholder placeholder-xs col-9"></div>
              <div class="placeholder placeholder-xs col-7"></div>
            </div>
            <div class="col-2 ms-auto text-end">
              <div class="placeholder placeholder-xs col-8"></div>
              <div class="placeholder placeholder-xs col-10"></div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row align-items-center">
            <div class="col-7">
              <div class="placeholder placeholder-xs col-9"></div>
              <div class="placeholder placeholder-xs col-7"></div>
            </div>
            <div class="col-2 ms-auto text-end">
              <div class="placeholder placeholder-xs col-8"></div>
              <div class="placeholder placeholder-xs col-10"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingLineComponent",
  props: {
    show: Boolean,
  },
  data() {
    return {
      isShow: false,
    }
  },
  created: function () {
    this.isShow = this.show;
  },
  watch: {
    show: {
      // deep: true,  // 深度监听
      handler(newVal) {
        this.isShow = newVal;
      }
    }
  }
}
</script>