import { createRouter, createWebHistory } from 'vue-router';
// 1、引入组件
import Home from "./view/Home.vue"
//help center
import ProductItem from "./view/product/ProductItem.vue"
import Search from "./view/product/Search.vue"
import FaqItem from "./view/product/FaqItem.vue"
//user manual
import UserManualItem from "./view/user_manual/Item.vue"
//ulooper update download
import ULooperUpdateDownload from "./view/UlooperUpdaterDownload.vue"
/**
 * 2、配置路由映射关系
 */
const routes = [
  {
    path: '/',
    name: 'app',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      title: "Search"
    }
  },
  {
    path: '/faq/:product_id/:id',
    name: 'faq',
    component: FaqItem,
    meta: {
      title: "FAQ"
    }
  },
  {
    path: '/product/:short_name',
    name: 'product',
    component: ProductItem,
    meta: {
      title: "Product"
    }
  },
  {
    path: '/user_manual/:id',
    name: 'user_manual',
    component: UserManualItem,
    meta: {
      title: "User Manual"
    }
  },
  {
    path: '/ulooperupdater',
    name: 'ulooperupdater',
    component: ULooperUpdateDownload,
    meta: {
      title: "ULooper Updater"
    }
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    component: () => import("./view/NotFound.vue")
  }
]
const router = createRouter({
  // 指定模式
  /**
   * createWebHashHistory 带 # 号
   * createWebHistory 不带 # 号
   */
  history: createWebHistory(),
  // 下面这个 可以写成ES6的简写 routers
  routes: routes
})

export default router