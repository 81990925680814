<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ $t('AppName') }}</h1>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-7">
                <div class="input-group">
                  <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">{{ productName() }}
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" v-for="item in list_products" :key="item.id"
                      @click="changeProduct(item)">
                      {{ item.name }}</a>
                  </div>
                  <input type="text" class="form-control" placeholder="Search FAQs…" v-model="keywords"
                    autocomplete="off" @keyup.enter="search()">
                </div>
              </div>
              <div class="col-1">
                <a href="/search" class="btn btn-icon" aria-label="Button" @click="search()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                  </svg>
                </a>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-deck row-cards">
          <div class="col-12">
            <div class="row row-cards">
              <Loading :show="loading"></Loading>
              <div class="col-sm-6 col-lg-3" v-for="item in list_products" :key="item.id">
                <div class="card card-link card-link-pop">
                  <a :href="'/product/' + item.short_name.toLowerCase()" class="d-block"><img :src="item.show_img_url"
                      class="card-img-top"></a>
                  <!-- <div class="ribbon bg-red">YT</div> -->
                  <div class="card-body">
                    <h1 class="text-center" style="min-height: 70px;">{{ item.name }}</h1>
                    <div>
                      <a :href="getUserManualUrl(item)" class="text-reset"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b0b0b0" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-book">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                          <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                          <path d="M3 6l0 13" />
                          <path d="M12 6l0 13" />
                          <path d="M21 6l0 13" />
                        </svg> {{ $t("UserManual") }}</a>
                    </div>
                    <div style="margin-top: 5px;">
                      <a :href="'/product/' + item.short_name.toLowerCase()" class="text-reset"><svg
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="#b0b0b0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                          <path d="M12 9h.01" />
                          <path d="M11 12h1v4h1" />
                        </svg> {{ $t("ReadInstructions") }}</a>
                    </div>
                    <div style="height: 50px;margin-top: 5px;">
                      <div v-if="item.app_ios_download != ''">
                        <a :href="item.app_ios_download" class="text-reset"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="#b0b0b0"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-brand-apple">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M15.079 5.999l.239 .012c1.43 .097 3.434 1.013 4.508 2.586a1 1 0 0 1 -.344 1.44c-.05 .028 -.372 .158 -.497 .217a4.15 4.15 0 0 0 -.722 .431c-.614 .461 -.948 1.009 -.942 1.694c.01 .885 .339 1.454 .907 1.846c.208 .143 .436 .253 .666 .33c.126 .043 .426 .116 .444 .122a1 1 0 0 1 .662 .942c0 2.621 -3.04 6.381 -5.286 6.381c-.79 0 -1.272 -.091 -1.983 -.315l-.098 -.031c-.463 -.146 -.702 -.192 -1.133 -.192c-.52 0 -.863 .06 -1.518 .237l-.197 .053c-.575 .153 -.964 .226 -1.5 .248c-2.749 0 -5.285 -5.093 -5.285 -9.072c0 -3.87 1.786 -6.92 5.286 -6.92c.297 0 .598 .045 .909 .128c.403 .107 .774 .26 1.296 .508c.787 .374 .948 .44 1.009 .44h.016c.03 -.003 .128 -.047 1.056 -.457c1.061 -.467 1.864 -.685 2.746 -.616l-.24 -.012z" />
                            <path d="M14 1a1 1 0 0 1 1 1a3 3 0 0 1 -3 3a1 1 0 0 1 -1 -1a3 3 0 0 1 3 -3z" />
                          </svg> AIRSTEP APP</a>
                      </div>
                      <div style="margin-top: 5px;" v-if="item.app_android_download != ''">
                        <a :href="item.app_android_download" class="text-reset"><img src="/img/Android_Robot.svg"
                            height="15" width="15" style="margin-left:2px;margin-right: 3px;"> AIRSTEP APP</a>
                      </div>
                      <div style="margin-top: 5px;" v-if="item.app_windows_download != ''">
                        <a :href="item.app_windows_download" class="text-reset"><img src="/img/Windows.svg" height="15"
                            width="15" style="margin-left:2px;margin-right: 3px;">
                          Windows Driver</a>
                      </div>
                      <div style="margin-top: 5px;" v-if="item.updater_download != ''">
                        <a :href="item.updater_download" class="text-reset"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b0b0b0" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-download">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                            <path d="M7 11l5 5l5 -5" />
                            <path d="M12 4l0 12" />
                          </svg> {{ $t('FirmwareUpdate') }}</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../components/HeaderBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
import Loading from '../components/Loading.vue'
import { global } from '../global.js'

export default {
  name: 'HomePage',
  data() {
    return {
      global: global,
      list_products: [],
      keywords: "",
      current_product_id: 0,
      current_product_name: "",
      loading: true,
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
    Loading,
  },
  methods: {
    getQuickStartGuideUrl: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.quick_start_guide_url_zh;
      } else {
        return item.quick_start_guide_url;
      }
    },
    getUserManualUrl: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.user_manual_url_zh;
      } else {
        return item.user_manual_url;
      }
    },
    productName: function () {
      if (this.current_product_name == "" || this.current_product_name == undefined) {
        return this.$t("Products");
      } else {
        return this.current_product_name;
      }
    },
    search: function () {
      if (this.keywords != "") {
        this.$router.push({
          path: '/search',
          query: { product_id: this.current_product_id, keywords: this.keywords, product_name: this.current_product_name }
        })
      }
    },
    changeProduct: function (item) {
      this.current_product_id = item.id;
      this.current_product_name = item.name;
    },
    get_products_list: function () {
      global.postData("help_center/list_products")
        .then(data => {
          if (data.code == 200) {
            this.loading = false;
            this.list_products = data.body;
          }
        });
    }
  },
  created() {
    this.get_products_list();
  },
}
</script>
<style scoped>
.h1 {
  font-size: 40px;
}

sub {
  color: #d80e0e;
}
</style>