<template>
  <!--分页组件-->
  <ul class="pagination m-0 ms-auto" v-if="pageConfig.total">
    <!--上一页-->
    <li class="page-item" @click="prePage" :class="{ disabled: currentPage === 1 }">
      <a class="page-link" :href="currentUrl" tabindex="-1" aria-disabled="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
          stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M15 6l-6 6l6 6"></path>
        </svg>
        Prev
      </a>
    </li>
    <!--总页数小于8的-->
    <template v-if="pageTotal <= showPageNo">
      <li class="page-item" v-for="i in pageTotal" @click="changeCurrentPage(i)" :class="{ active: i === currentPage }"
        :key="i"><a class="page-link" :href="currentUrl">{{ i }}</a></li>
    </template>
    <template v-else-if="currentPage < 4">
      <li class="page-item" v-for="i in 6" @click="changeCurrentPage(i)" :class="{ active: i === currentPage }" :key="i">
        <a class="page-link" :href="currentUrl">{{ i }}</a>
      </li>
      <li class="page-item disabled">···</li>
      <li class="page-item" @click="changeCurrentPage(pageTotal)">
        <a class="page-link" :href="currentUrl">{{ pageTotal }}</a>
      </li>
    </template>
    <template v-else-if="currentPage > pageTotal - 4">
      <li class="page-item" @click="changeCurrentPage(1)">
        <a class="page-link" :href="currentUrl">1</a>
      </li>
      <li class="page-item disabled">···</li>
      <li class="page-item" v-for="i in 6" @click="changeCurrentPage(i + (pageTotal - 6))"
        :class="{ active: (i + (pageTotal - 6)) === currentPage }" :key="i">
        <a class="page-link" :href="currentUrl">{{ i + (pageTotal - 6) }}</a>
      </li>
    </template>
    <template v-else>
      <li class="page-item" @click="changeCurrentPage(1)"><a class="page-link" :href="currentUrl">1</a></li>
      <li class="page-item disabled">···</li>
      <li class="page-item" @click="changeCurrentPage(currentPage - 2)">
        <a class="page-link" :href="currentUrl">{{ currentPage - 2 }}</a>
      </li>
      <li class="page-item" @click="changeCurrentPage(currentPage - 1)">
        <a class="page-link" :href="currentUrl">{{ currentPage - 1 }}</a>
      </li>
      <li class="page-item active">
        <a class="page-link" :href="currentUrl">{{ currentPage }}</a>
      </li>
      <li class="page-item" @click="changeCurrentPage(currentPage + 1)">
        <a class="page-link" :href="currentUrl">{{ currentPage + 1 }}</a>
      </li>
      <li class="page-item" @click="changeCurrentPage(currentPage + 2)">
        <a class="page-link" :href="currentUrl">{{ currentPage + 2 }}</a>
      </li>
      <li class="page-item disabled">···</li>
      <li class="page-item" @click="changeCurrentPage(pageTotal)">
        <a class="page-link" :href="currentUrl">{{ pageTotal }}</a>
      </li>
    </template>
    <!--下一页-->
    <li class="page-item" @click="nextPage" :class="{ disabled: currentPage === pageTotal }">
      <a class="page-link" :href="currentUrl">
        Next
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
          stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M9 6l6 6l-6 6"></path>
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "pageComponent",
  props: {
    // 分页配置
    pageConfig: {
      type: Object, require: true, default() {
        return {
          pageSize: 10,     //一页的数据条数
          pageNo: 0,        //当前页的索引
          total: 0,         //总的数据条数
          pageTotal: 0      //总的页数
        }
      }
    }
  },
  data() {
    return {
      //默认显示
      showPageNo: 8,
      currentPage: 0,
      currentUrl: "#" + this.$route.path,
    }
  },
  methods: {
    prePage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.$emit('changeCurrentPage', this.currentPage)
      }
    },
    nextPage() {
      if (this.currentPage < this.pageTotal) {
        this.currentPage += 1
        this.$emit('changeCurrentPage', this.currentPage)
      }
    },
    changeCurrentPage(i) {
      this.currentPage = i
      this.$emit('changeCurrentPage', this.currentPage)
    }
  },
  computed: {
    //计算总页数，如果传了pageTotal,直接取pageTotal的值，如果传了total，那么根据pageSize去计算
    pageTotal() {
      const config = this.pageConfig
      if (config.pageTotal) {
        return config.pageTotal
      } else {
        if (config.pageSize && config.total) {
          return Math.ceil(config.total / config.pageSize)
        } else {
          return 0
        }
      }
    }
  },
  created: function () {
    this.currentPage = this.pageConfig.pageNo || 0
  }
}
</script>

<style lang="scss">
.pageComponent {
  button {
    margin: 0 5px;
    background-color: #f4f4f5;
    color: #606266;
    outline: none;
    border-radius: 2px;
    padding: 0 4px;
    vertical-align: top;
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    border: 0;

    &[disabled] {
      color: #c0c4cc;
      cursor: not-allowed;
    }

    &.active {
      cursor: not-allowed;
      background-color: #409eff;
      color: #fff;
    }
  }
}
</style>