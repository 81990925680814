
<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ getTitle() }} </h1>
          </div>
        </div>
        <div class="card-body">
          <ol class="breadcrumb breadcrumb-arrows">
            <li class="breadcrumb-item"><a href="/home"> {{ $t('Home') }}</a></li>
            <li class="breadcrumb-item"><a :href="'/product/' + product.short_name.toLowerCase()">{{ product.name }}</a>
            </li>
          </ol>
        </div>
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="card card-lg">
              <div class="card-body markdown" id="preview">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../../components/HeaderBlock.vue'
import FooterBlock from '../../components/FooterBlock.vue'
import VditorPreview from 'vditor/dist/method.min'
VditorPreview.mermaidRender(document)

//
import { global } from '../../global.js'
export default {
  name: 'ProductItemPage',
  data() {
    return {
      global: global,
      item: {},
      i18n: "en",
      product: { name: 'AIRSTEP', short_name: 'as' },
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getTitle: function () {
      this.i18n = this.$i18n.locale;
      if (this.$i18n.locale == "zh") {
        if (this.item.title_zh == "") {
          return this.item.title;
        }
        return this.item.title_zh;
      } else {
        return this.item.title;
      }
    },
    getContent: function () {
      if (this.$i18n.locale == "zh") {
        if (this.item.content_zh != undefined) {
          VditorPreview.md2html(this.item.content_zh).then((c) => {
            document.getElementById("preview").innerHTML = c;
          });
        } else if (this.item.content_zh == "") {
          VditorPreview.md2html(this.item.content).then((c) => {
            document.getElementById("preview").innerHTML = c;
          });
        }
      } else {
        VditorPreview.md2html(this.item.content).then((c) => {
          document.getElementById("preview").innerHTML = c;
        });
      }
    },
    getItem: function () {
      global.postData("help_center/product_faq_item", {
        product_id: parseInt(this.$route.params.product_id),
        id: parseInt(this.$route.params.id),
      })
        .then(data => {
          if (data != null) {
            if (data.code == 200) {
              this.item = data.body;
              this.product = data.body.product;
              this.getContent();
            }
          }
        });
    },
  },
  watch: {
    i18n: function (newV, oldV) {
      console.log(newV, oldV);
      this.getContent();
    }
  }
}
</script>
<style scoped>
.markdown {
  min-height: 400px;
}
</style>