<template>
  <div class="empty" v-if="isShow">
    <div class="container-xl " v-if="isShow" style="text-align:left">
      <div class="row row-cards">
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card placeholder-glow">
            <div class="ratio ratio-21x9 card-img-top placeholder"></div>
            <div class="card-body">
              <div class="placeholder col-9 mb-3"></div>
              <div class="placeholder placeholder-xs col-10"></div>
              <div class="placeholder placeholder-xs col-11"></div>
              <div class="mt-3">
                <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="text-center">
        <div class="text-secondary">Loading...</div>
        <div class="progress progress-sm">
          <div class="progress-bar progress-bar-indeterminate"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingComponent",
  props: {
    show: Boolean,
  },
  data() {
    return {
      isShow: false,
    }
  },
  created: function () {
    this.isShow = this.show;
  },
  watch: {
    show: {
      // deep: true,  // 深度监听
      handler(newVal) {
        this.isShow = newVal;
      }
    }
  }
}
</script>