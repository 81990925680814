<template>
  <Transition name="fade">
    <div v-if="isShow" class="scroll-top">
      <div class="p-2.5 rounded-sm bg-accent-success text-primary-lg hover:bg-accent-y cursor-pointer transition"
        @click="scrollTop">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
        </svg>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ScrollTopBtn',

  data() {
    return {
      elTop: 0,
      isShow: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.scrolling)
  },

  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
      this.isShow = false;
    },

    scrolling() {
      const scrolltoTop = window.scrollY
      const scrollLength = scrolltoTop - this.elTop
      this.elTop = scrolltoTop
      if (scrollLength < 0 && this.elTop < 200) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },
  watch: {
    isShow: {
      handler(newVal) {
        this.isShow = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-top {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 10px;
  bottom: 10px;
  line-height: 50px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  color: black;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>
