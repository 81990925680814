<template>
  <footer class="footer footer-transparent d-print-none">
    <hr>
    <div class="container-xl">
      <div class="row text-center align-items-center flex-row-reverse">
        <ul class="list-inline list-inline-dots ">
          <li class="list-inline-item">
            Copyright &copy; 2024
            <a href="." class="link-secondary">XSONIC Inc.</a>
            All rights reserved.
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>