<!--
 * @Date: 2023-07-06 11:52:51
 * @LastEditors: teenagex
 * @LastEditTime: 2023-07-06 17:51:02
-->
<template>
  <div class="empty" v-if="isShow">
    <p class="empty-title">{{ $t("NoResults") }}</p>
    <p class="empty-subtitle text-secondary">
      {{ $t("NoResultsAdj") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "NoDataComponent",
  props: {
    show: Boolean,
  },
  data() {
    return {
      isShow: false,
    }
  },
  created: function () {
    this.isShow = this.show;
  },
  watch: {
    show: {
      // deep: true,  // 深度监听
      handler(newVal) {
        this.isShow = newVal;
      }
    }
  }
}
</script>