
<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ productItem.name || "..." }}</h1>
          </div>
          <div class="col-12 text-center text-secondary">
            {{ productItem.desc || "..." }}
          </div>
          <div
            :style="'background: url(' + productImage + ') no-repeat center center;background-size: cover; min-height: ' + getMinHeight(productItem) + 'px;'">
          </div>
          <div class="col-12 text-center ">
            <h2 v-if="getUserManualUrl(productItem) != ''"> <a :href="getUserManualUrl(productItem)"
                class="text-reset  btn-user-manual">{{
                  $t("UserManual")
                }}</a></h2>
            <!-- <h2 v-if="getQuickStartGuideUrl(item) != ''"> <a :href="getQuickStartGuideUrl(item)" class="text-reset">{{
              $t("QuickStartGuide") }}</a></h2> -->
          </div>
        </div>
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="col-12">
          <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ $t("Faqs") || "..." }}</h1>
        </div>
        <LoadingLine :show="loading"></LoadingLine>
        <div v-for="item in faqList" :key="item.id">
          <div v-if="item.list.length > 0">
            <h2 class="mb-3 mt-3" v-if="isEmpty(item)">{{ getTitle(item) }}</h2>
            <div :id="'faq-' + item.id" class="accordion" role="tablist" aria-multiselectable="true">
              <div v-for="it in item.list" :key="it.id">
                <div class="accordion-item mt-3" v-if="getQ(it) != ''">
                  <div class="accordion-header" role="tab">
                    <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      :data-bs-target="'#faq-' + item.id + '-' + it.id" aria-expanded="false">{{ getQ(it) }}</button>
                  </div>
                  <div :id="'faq-' + item.id + '-' + it.id" class="accordion-collapse collapse" role="tabpanel"
                    :data-bs-parent="'#faq-' + item.id">
                    <div class="accordion-body pt-0 markdown" :id="'content-' + item.id + '-' + it.id">
                      {{ getA(it, item) }}
                    </div>
                    <button class="btn copy btn-ghost-secondary active btn-xs"
                      :data-clipboard-text="'https://help.xsonicaudio.com/faq/' + `${productItem.id}` + '/' + `${it.id}`">{{
                        $t("CopyShareLink")
                      }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .. -->
        <div class="col-sm-12">
          <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">{{ $t("EmailUs") }}</h1>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="text-center ">helpcenter@xsonicaudio.com</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../../components/HeaderBlock.vue'
import FooterBlock from '../../components/FooterBlock.vue'
import LoadingLine from '../../components/LoadingLine.vue'
import VditorPreview from 'vditor/dist/method.min'
VditorPreview.mermaidRender(document)
import Clipboard from "clipboard";
//
import { global } from '../../global.js'
export default {
  name: 'ProductItemPage',
  data() {
    return {
      global: global,
      productImage: "/upload/products/" + this.$route.params.short_name + ".png",
      productItem: {},
      faqList: [],
      loading: true,
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
    LoadingLine
  },
  mounted() {
    const clipboard = new Clipboard('.copy');
    clipboard.on("success", (e) => {
      e.clearSelection();
      global.showTips(this.$t("Copied"));
    });
  },
  methods: {
    getMinHeight: function (item) {
      let minHeight = 400;
      switch (item.short_name) {
        case "ULP":
          minHeight = 650;
          break;
        case "XT": case "XTP":
          minHeight = 500;
          break;

        default:
          break;
      }
      return minHeight;
    },
    isEmpty: function (item) {
      let L = 0;
      if (this.$i18n.locale == "zh") {
        for (const key in item.list) {
          const el = item.list[key];
          if (el.title_zh != "") {
            L++;
          }
        }
        return L > 0;
      } else {
        for (const key in item.list) {
          const el = item.list[key];
          if (el.title != "") {
            L++;
          }
        }
        return L > 0;
      }
    },
    getQuickStartGuideUrl: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.quick_start_guide_url_zh;
      } else {
        return item.quick_start_guide_url;
      }
    },
    getUserManualUrl: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.user_manual_url_zh;
      } else {
        return item.user_manual_url;
      }
    },
    getTitle: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.name_zh;
      } else {
        return item.name;
      }
    },
    getQ: function (item) {
      if (this.$i18n.locale == "zh") {
        return item.title_zh;
      } else {
        return item.title;
      }
    },
    getA: function (it, item) {
      if (this.$i18n.locale == "zh") {
        VditorPreview.md2html(it.content_zh).then((c) => {
          document.querySelector('#content-' + item.id + '-' + it.id).innerHTML = c;
        });
      } else {
        VditorPreview.md2html(it.content).then((c) => {
          document.querySelector('#content-' + item.id + '-' + it.id).innerHTML = c;
        });
      }
    },
    getProductItem: function () {
      global.postData("help_center/product_item", {
        short_name: this.$route.params.short_name,
      })
        .then(data => {
          if (data != null) {
            if (data.code == 200) {
              this.productItem = data.body;
              this.productImage = this.productItem.show_img_url.replace(".png", "_big.png");
              this.getFaqList(this.productItem.id);
            }
          }
        });
    },
    getFaqList: function (id) {
      global.postData("help_center/product_faq_list", {
        product_id: id,
      })
        .then(data => {
          if (data != null) {
            if (data.code == 200) {
              this.faqList = data.body;
              this.loading = false;
            }
          }
        });
    },
  },
  created() {
    this.getProductItem();
  },
}
</script>
<style scoped>
.h1 {
  font-size: 40px;
}

.copy {
  font-size: 12px;
  margin: 20px;
  padding: 2px 5px;
}

.btn-user-manual {
  border-style: dashed;
  border: 1px black solid;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: unset;
  font-size: larger;
}

.btn-user-manual:hover {
  background-color: black;
  color: #fff !important;
  text-decoration: none;
}
</style>